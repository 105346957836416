// eslint-disable-next-line import/no-cycle
import clientFactory from '@/v1/network/clientFactory';
import { addReferrerHeader } from '@/v1/network/interceptors/referrerInterceptor';

const noopFail = Promise.reject.bind(Promise);

const instance = clientFactory({
  baseURL: import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL,
});

instance.interceptors.request.use(addReferrerHeader, noopFail);

export default instance;
