import WidgetService from '@/v1/services/widget/widgetService.v1';
import { captureException } from '@/v1/packages/common/plugins/sentry';
import TokenService from '@/v1/packages/authentication/services/token';
import { getDeviceId } from '@/utils/loadNSure';

import clientWidget from '@/v1/network/clients/widget';
import clientProcessing from '@/v1/network/clients/processing';
import Api from '@/v1/packages/common/services/api';

import store from '@/v1/store';

const params = {};
let oneTimeToken = null;
let oneTimeTokenPromise = null;

export const oneTimeTokenLogin = async () => {
  if (oneTimeTokenPromise) {
    return oneTimeTokenPromise;
  }

  if (!oneTimeToken) {
    return;
  }

  const nSureDeviceId = await getDeviceId();

  oneTimeTokenPromise = WidgetService.passwordlessLogin({
    ...params,
    oneTimeToken,
    nSurePartnerId: store.getters['request/nSure'].nSurePartnerId,
    nSureDeviceId,
  })
    .then(response => store.dispatch('setTokens', response.data, { root: true }))
    .catch(error => captureException({ error }));

  return oneTimeTokenPromise;
};

export const requestInterceptor = async config => {
  if (!TokenService.hasToken() && config.useAuth && oneTimeToken) {
    await oneTimeTokenLogin();

    oneTimeToken = null;
  }

  return config;
};

export const initOneTimeTokenLogin = ({ requestId, oneTimeToken: initialOneTimeToken, locale }) => {
  if (!initialOneTimeToken) {
    return;
  }

  params.requestId = requestId;
  params.locale = locale;

  oneTimeToken = initialOneTimeToken;

  TokenService.removeTokens();

  clientWidget.interceptors.request.use(
    requestInterceptor,
    error => Promise.reject(error),
  );
  clientProcessing.interceptors.request.use(
    requestInterceptor,
    error => Promise.reject(error),
  );
  Api().interceptors.request.use(
    requestInterceptor,
    error => Promise.reject(error),
  );
};

export function isOneTimeTokenExist() {
  return !!oneTimeToken;
}
