import { h } from 'vue';

import modalManager from './modalManager';

export default {
  name: 'Modal',

  model: {
    prop: 'show',
    event: 'update',
  },

  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      required: true,
    },
    target: {
      type: String,
      default: 'default',
    },
    isWithoutBlur: {
      type: Boolean,
      default: false,
    },
    isClosedAutomatically: {
      type: Boolean,
      default: false,
    },
  },

  data(){
    return {
      modalId: Symbol('modalId'),
      isModalRegistered: false,
    };
  },
  methods: {
    registerModal() {
      if (this.isModalRegistered) {
        return;
      }
      const holderDismiss = () => {
        this.$emit('reject');
      };

      // this is needed for passing value by link
      const self = this;
      const wrappedComponent = {
        functional: true,
        target: this.target,
        holderDismiss,
        render() {
          return h(self.component, {
            ...self.$attrs,
          });
        },
      };
      modalManager.registerModal({
        key: this.modalId,
        component: wrappedComponent,
      });

      this.isModalRegistered = true;
    },
    setShownState(state){
      modalManager.setShownState({
        target: this.target,
        key: this.modalId,
        state,
        isWithoutBlur: this.isWithoutBlur,
        isClosedAutomatically: this.isClosedAutomatically,
      });
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(currentValue, prevValue) {
        this.registerModal();
        if (typeof prevValue === 'undefined') {
          if (!currentValue) return;
        }
        this.setShownState(currentValue);
      },
    },
  },

  render() {
    return null;
  },

  beforeUnmount() {
    const state = modalManager.getModalState();

    if (state.isClosedAutomatically) {
      this.setShownState(false);
    }

    modalManager.unregisterModal({
      key: this.modalId,
    });
  },
};
