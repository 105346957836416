// TODO copied from common lib, DRY violation
// @see https://gitlab.com/techcloud/shared/frontend-common-lib/-/blob/master/src/services/token.js

import { StorageService } from '@/services/storageService';
import { isValid as isValidUUID } from '@/utils/uuid';
import { ref } from 'vue';

const KEYS = {
  JWT: '__jwt',
  JWT_EXPIRATION: '__jwt-exp',
  REFRESH_TOKEN: '__refresh_token',
  REFRESH_TOKEN_EXPIRATION: '__refresh_token-exp',
};

const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      // eslint-disable-next-line
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const isTokenExist = ref(StorageService.get(KEYS.JWT) !== undefined);
export const useTokenService = () => {
  return {
    isTokenExist,
  };
};

export default {
  getUser() {
    const hasToken = this.hasToken();
    if (hasToken) {
      const token = this.getToken();
      const { sub, email } = parseJwt(token);

      return {
        id: sub,
        email,
      };
    }

    return null;
  },

  removeTokens() {
    StorageService.remove(KEYS.JWT);
    StorageService.remove(KEYS.JWT_EXPIRATION);
    StorageService.remove(KEYS.REFRESH_TOKEN);
    StorageService.remove(KEYS.REFRESH_TOKEN_EXPIRATION);
    isTokenExist.value = false;
  },

  hasRefreshToken() {
    return StorageService.get(KEYS.REFRESH_TOKEN) !== undefined;
  },

  hasToken() {
    return StorageService.get(KEYS.JWT) !== undefined;
  },

  isTokenExpired() {
    return isNaN(this.getTokenExpirationDate()) || this.getTokenExpirationDate() < new Date();
  },

  getToken() {
    return StorageService.get(KEYS.JWT);
  },

  getTokenExpirationString() {
    return StorageService.get(KEYS.JWT_EXPIRATION);
  },

  getTokenExpirationDate() {
    return new Date(this.getTokenExpirationString());
  },

  getRefreshToken() {
    return StorageService.get(KEYS.REFRESH_TOKEN);
  },

  getRefreshTokenExpirationString() {
    return StorageService.get(KEYS.REFRESH_TOKEN_EXPIRATION);
  },

  setTokens({ jwt, refreshToken, jwtExpiresAt, refreshTokenExpiresAt }) {
    StorageService.set(KEYS.JWT, jwt, new Date(refreshTokenExpiresAt));

    StorageService.set(KEYS.JWT_EXPIRATION, jwtExpiresAt, new Date(refreshTokenExpiresAt));

    StorageService.set(KEYS.REFRESH_TOKEN, refreshToken, new Date(refreshTokenExpiresAt));

    StorageService.set(KEYS.REFRESH_TOKEN_EXPIRATION, refreshTokenExpiresAt, new Date(refreshTokenExpiresAt));

    isTokenExist.value = true;
  },

  verifyTokenWithEmail(email = '') {
    if (!email) {
      // XXX: if we don't have email, we assume it's ok
      return true;
    }

    const token = this.getToken();
    const { email: storedEmail } = parseJwt(token);
    if (!email) {
      return true;
    }

    return email === '' || email.toLowerCase() === storedEmail.toLowerCase();
  },

  verifyTokenWithBusinessAccountProductId(sourceInfo = null) {
    if (sourceInfo === null) {
      // XXX: No extra data. early return
      return true;
    }

    const { source: businessAccountProductUuid } = sourceInfo;
    const jwt = this.getToken();

    return this.isProductIdInJwt(businessAccountProductUuid, jwt);
  },

  isProductIdInJwt(businessAccountProductUuid, jwt) {
    if (!businessAccountProductUuid) {
      // XXX: if we don't have product ID, we assume it's ok
      return true;
    }
    const { extra = null } = parseJwt(jwt);
    const extraBusinessAccountProductUuid = extra?.business_account.product_id;

    if (!isValidUUID(extraBusinessAccountProductUuid)) {
      // XXX: in case when extra.business_account.product_id type is int or is null
      return true;
    }

    return businessAccountProductUuid === extraBusinessAccountProductUuid;
  },

  setRefreshToken(refreshToken) {
    if (this.hasRefreshToken() || !refreshToken) {
      return;
    }

    // XXX: timestamp now = 1 minute. should be enough
    const refreshTokenExpiresAt = new Date().getTime() + 60000;
    StorageService.set(KEYS.REFRESH_TOKEN, refreshToken, new Date(refreshTokenExpiresAt));
  },
};
