import { computed, h, ref, onMounted, reactive, onUpdated } from 'vue';

export default {
  name: 'ClampWalletAddress',
  setup(props, context) {
    const el = ref(null);
    const font = reactive({});
    const elementWidth = ref(0);

    const fullText = computed(() => {
      const [{ children: textLine }] = context.slots.default();
      return textLine;
    });

    const getFontDetails = () => {
      if (!el.value) return;
      const elStyles = window.getComputedStyle(el.value, null);
      const [fontFamily] = elStyles.getPropertyValue('font-family').split(', ');
      const fontSize = elStyles.getPropertyValue('font-size');

      font.family = fontFamily;
      font.size = fontSize;

      const width = el.value.clientWidth || el.value.parentNode.clientWidth;

      if (!width) {
        return;
      }

      elementWidth.value = width;
    };

    onMounted(getFontDetails);
    onUpdated(getFontDetails);
    window.addEventListener('resize', getFontDetails);
    
    const clampedText = computed(() => {
      if (!el.value || !fullText.value) return '';

      const originalText = fullText.value;
      const fontStr = [font.size, font.family].join(' ');

      if (elementWidth.value === 0) return originalText;

      let textWidth = displayTextWidth(originalText, fontStr);
      let lettersToClamp = 1;
      const halfText = Math.floor(originalText.length / 2);
      let clampedTextStr = fullText.value;

      for (let i = 0; i <= originalText.length; i++) {
        if (textWidth <= elementWidth.value) {
          break;
        }

        const leftText = originalText.slice(0, halfText - lettersToClamp);
        const rightText = originalText.slice(-(halfText - lettersToClamp));
        clampedTextStr = [leftText, '...', rightText].join('');

        textWidth = displayTextWidth(clampedTextStr, fontStr);
        lettersToClamp += 1;
      }

      return clampedTextStr;
    });

    return () => h('div', {
      ref: el,
      style: 'width: 100%',
    }, clampedText.value);
  },
};

function displayTextWidth(text, fontStr) {
  const canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement('canvas'));
  const ctx = canvas.getContext('2d');
  ctx.font = fontStr;
  const metrics = ctx.measureText(text);
  return metrics.width;
}
