import {
  captureException as sentryCaptureException,
  configureScope,
} from '@/v1/plugins/sentry';

export const setUser = user => {
  configureScope(scope => scope.setUser(user));
};

export const captureException = ({ error, extra = {} }) => {
  sentryCaptureException(error, {
    extra,
  });
};
