import getParams from '@/v1/utils/getParams';
import store from '@/v1/store';
import { isElectron } from '@/utils/detect';
import { isInsideIframe } from '@/utils/isInsideIframe';

const APPLE_PAY = 'applePay';

export function isApplePayOneClickFlow() {
  const { indexPage } = getParams();
  return indexPage === APPLE_PAY && store.getters.isOneClickFlow && window.ApplePaySession?.canMakePayments() && !isElectron() && !isInsideIframe();
}

const storeLocal = {};
export const useApplePayOneClickStore = () => {
  const setPaymentData = ({ token, cardholderName, currency, language, billingAddress }) => {
    storeLocal.token = token;
    storeLocal.cardholderName = cardholderName;
    storeLocal.currency = currency;
    storeLocal.language = language;
    storeLocal.billingAddress = billingAddress;
  };

  function isStorageExist(){
    return Object.keys(storeLocal).length > 0;
  }

  const getPaymentData = () => storeLocal;

  return {
    setPaymentData,
    getPaymentData,
    isStorageExist,
  };
};
