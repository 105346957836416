export default class {
  constructor(id = null) {
    this.id = id;
  }

  getId() {
    if (this.id === null) {
      throw new Error('Trying to get non-existing partner id');
    }

    return this.id;
  }

  hasId() {
    return this.id !== null;
  }
}
