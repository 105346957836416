import { getCorrelationId } from '@/v1/packages/common/services/correlation';
import { filterUnwantedExceptions, filterOldBrowsers, ignoredErrorMessages, denyUrls } from '@/v1/plugins/sentry-ignore-config';
import TokenService from '@/v1/packages/common/services/token';
import store from '@/v1/store';
import { getAllowedNetworkUrls } from '@/v1/plugins/sentry-config';

const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
const environment = import.meta.env.VITE_APP_DEPLOYMENT_ENV;

let replay;
let isSentryInit = false;
const errorsStack = [];

export const startReplay = () => {
  try {
    if (replay) replay.start();
  } catch {
    replay?.flush();
  }
};

export const initSentry = async (app, router, initialTags = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const { init, BrowserTracing, vueRouterInstrumentation } = await import('@sentry/vue');

  init({
    app,
    dsn,
    environment,
    integrations: [new BrowserTracing({
      routingInstrumentation: vueRouterInstrumentation(router),
    })],
    ignoreErrors: ignoredErrorMessages,
    denyUrls,
    beforeSend: async (event, hint) => {
      const oldBrowser = await filterOldBrowsers();
      if (oldBrowser) return null;

      const unwantedException = filterUnwantedExceptions(event, hint);
      if (unwantedException) return null;

      return event;
    },
    tracesSampleRate: 0.25,
    tracePropagationTargets: [import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL], // XXX: update with services urls that support sentry custom headers (sentry-trace, baggage)
    replaysSessionSampleRate: 0.15,
    replaysOnErrorSampleRate: 1.0,
  });

  setTags({
    correlation_id: getCorrelationId(),
    ...initialTags,
  });

  isSentryInit = true;

  errorsStack.forEach(errorData => {
    captureException(...errorData);
  });
};

export const setTags = async tags => {
  const { configureScope } = await import('@sentry/vue');

  configureScope(scope => {
    Object.entries(tags).forEach(([key, value]) => {
      scope.setTag(key, value);
    });
  });
};

export const addSentryReplay = async () => {
  const { getCurrentHub, Replay, setUser } = await import('@sentry/vue');

  const client = getCurrentHub().getClient();

  if (!client) {
    return;
  }

  replay = new Replay({
    maskAllText: false,
    maskAllInputs: true,
    blockAllMedia: false,
    networkDetailAllowUrls: getAllowedNetworkUrls(),
    networkCaptureBodies: true,
    beforeAddRecordingEvent: event => {
      const user = TokenService.getUser();
      const invoice = store.getters['transaction/invoice'];

      if (user) {
        setUser(user);
      }
      if (invoice){
        setTags({ invoice });
      }
      return event;
    },
  });

  client.addIntegration(replay);

  if (store.getters.isUkCustomer) {
    startReplay();
  }
};

export const captureException = async (...args) => {
  if (!isSentryInit) {
    errorsStack.push(args);
    return;
  }

  const { captureException: sentryCaptureException } = await import('@sentry/vue');
  return sentryCaptureException(...args);
};

export const captureEvent = async (...args) => {
  const { captureEvent: sentryCaptureEvent } = await import('@sentry/vue');
  return sentryCaptureEvent(...args);
};

export const captureMessage = async (...args) => {
  const { captureMessage: sentryCaptureMessage } = await import('@sentry/vue');
  return sentryCaptureMessage(...args);
};

export const configureScope = async (...args) => {
  const { configureScope: sentryConfigureScope } = await import('@sentry/vue');
  return sentryConfigureScope(...args);
};
