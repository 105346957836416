import { executeEverySecond } from '../../utils/secondsTicker';

const SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_MILLISECONDS = 60000;

let isTimerStarted = false;

export default {
  namespaced: true,
  state: {
    time: null,
    dynamic: null,
    secondsLeft: 0,
  },
  getters: {
    time: state => state.time,
    isApproximate: state => state.dynamic && state.time !== null && state.secondsLeft <= 0,
    hasCryptoTimer: state => state.dynamic !== false,
    secondsLeft: state => state.secondsLeft,
  },
  mutations: {
    setTime(state, payload) {
      state.time = payload;
    },
    setDynamic(state, payload) {
      state.dynamic = payload;
    },
    setSecondsLeft(state, payload) {
      state.secondsLeft = payload;
    },
  },
  actions: {
    init({ commit, dispatch, rootGetters }, { expiresAt, dynamic }) {
      let time = null;

      if (expiresAt) {
        // HACK: this was done to convert unix js format
        // @see https://stackoverflow.com/questions/68702467/convert-unix-timestamps-to-users-local-time-using-moment-js
        time = expiresAt * SECOND_IN_MILLISECONDS - rootGetters.serverTimeDiff;
      }
      commit('setTime', time);
      commit('setDynamic', dynamic);
      dispatch('startTimer');
    },
    startTimer({ commit, state, rootGetters }) {
      if (isTimerStarted) return;

      executeEverySecond(tickerTime => {
        const quoteExpirationTimer = rootGetters['widgetQuote/quote'].expiresAt;
        const quoteExpirationTimerTimestamp = new Date(quoteExpirationTimer).getTime();
        const difference = quoteExpirationTimerTimestamp - Date.now();

        const secondsLeft = (difference < MINUTE_IN_MILLISECONDS ? quoteExpirationTimerTimestamp : state.time) - tickerTime;
        commit('setSecondsLeft', secondsLeft / SECOND_IN_MILLISECONDS);
        isTimerStarted = true;
      }, true);
    },
  },
};
