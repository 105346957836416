import root from '@/v1/packages/common/store/index';
import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import TokenService from '@/v1/packages/authentication/services/token';
import storage from '@/services/appPersistStorage';
import ProcessingService from '@/v1/services/processing';
// eslint-disable-next-line import/no-cycle
import WidgetService from '@/v1/services/widget/widgetService.v1';
// eslint-disable-next-line import/no-cycle
import WidgetServiceV2 from '@/v1/services/widget/widgetService.v2';
import requestStore from '@/v1/store/request';
// eslint-disable-next-line import/no-cycle
import { refreshQuoteWithPaymentMethodSubscriber, widgetQuote } from '@/v1/store/widgetQuote';
import { transactionHistory } from '@/v1/store/transactionHistory';
import menuStore from './menu';
// eslint-disable-next-line import/no-cycle
import externalStore, { registerExtraModules } from '@/v1/store/externalStore';
// eslint-disable-next-line import/no-cycle
import exchangeForm from '@/v1/store/exchangeForm';
import { GLOBAL, REQUEST } from '@/v1/store/mutations';
import { getThemeName, loadPartnerJSON, loadPartnersCss } from '@/v1/utils/theme';
import { isOneClickFlowCanBeUsed } from '@/utils/oneClickFlow';
import authService from '@/v1/packages/authentication/services/auth';

import { ErrorType } from '@/v1/services/ErrorService';
import { setSiftAccount, setSiftSessionId, setSiftUserId } from '@/utils/sift';
import { AppCriticalError } from '@/exceptions/AppCriticalError';
import { UserForbiddenError } from '@/exceptions/UserForbiddenError';
import { isV1, isV2, setApiVersion } from '@/services/apiVersion';
// eslint-disable-next-line import/no-cycle
import PaymentMethodsV2 from '@/v1/store/v2/paymentMethods';
// eslint-disable-next-line import/no-cycle
import WidgetQuoteV2 from '@/v1/store/v2/widgetQuote';
import frictionless from '@/v1/store/v2/frictionless';
import { requestIdValidator } from '@/validators';
import { CRYPTO_FLOWS_MAP } from '@/constants/cryptoFlows';
import { loadHotJarScript } from '@/utils/loadHotJar';
import { parseJwt } from '@/utils/jwtParser';
import { setFrictionlessMode, setIsPaymentInitiated, setDepositCallbackUrl } from '@/services/frictionlessFlow';
import promoCode from '@/v1/store/v2/promoCode';
import { PRODUCT_TYPES } from '@/constants/productTypes';
import { waitUserInfo } from '@/utils/waitUserInfo';

const vuexLocal = new VuexPersistence({
  storage,
  key: 'widget',
  reducer: state => ({
    locale: state.locale,
    translationResource: state.translationResource,
    request: {
      requestId: state.request.requestId,
    },
  }),
});

const {
  SET_ERROR,
  SET_LANGUAGE_SWITCHING,
  SET_TRANSLATION_STATUS,
  SET_WIDGET_THEME_CONFIG,
  SET_IS_THEME_LOADING,
  SET_IS_INIT_COMPLETED,
  SET_LOCALE,
  SET_TRANSLATION_RESOURCE,
  SET_PRODUCT_ID,
  SET_PRODUCT_TYPE,
  SET_IS_REQUEST_WITH_QUOTE,
  SET_CLIENT,
  SET_CLIENT_ID,
  SET_NSURE,
  SET_SIFT,
  SET_IS_SET_TOKEN,
  SET_IS_PAYMENT_PROCESSOR_NOT_FOUND,
  SHOW_TRANSACTION_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP,
  SHOW_TRANSACTION_VALIDATION_ERROR_POPUP,
  SHOW_COOLING_OFF_USER_POPUP,
  HIDE_COOLING_OFF_USER_POPUP,
  SHOW_BANNED_USER_POPUP,
  SHOW_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP,
  TOGGLE_FCA_NOTIFICATION_POPUP,
  TOGGLE_APPLE_PAY_NOTIFICATION_POPUP,
  SET_CRYPTO_FLOW,
  SET_API_VERSION,
  SET_ONE_CLICK_FLOW,
  SET_SUCCESS_RETURN_URL,
  SET_FAILURE_RETURN_URL,
} = GLOBAL;

const LANG_PLACEHOLDER = '_LANG';
const FCA_COUNTRY_CODE_LIST = ['UK', 'GB'];

const rootStore = createStore({
  plugins: [vuexLocal.plugin],

  state: {
    isTranslationsLoaded: false,
    lastWidgetError: null,
    // TODO: merge two these configs when BE completely implement widget config
    themeConfig: {},
    isThemeLoading: true,
    isInitCompleted: false,
    locale: '',
    translationResource: '',
    productId: null,
    productType: PRODUCT_TYPES.WIDGET,
    isRequestWithQuote: false,
    client: {},
    clientId: null,
    nSure: {},
    sift: {},
    isSetToken: false,
    isLanguageSwitching: false,
    showTransactionCryptocurrencyIsNotAvailablePopup: false,
    showTransactionValidationErrorPopup: false,
    showCoolingOffUserPopup: false,
    messageCoolingOffUserPopup: '',
    showBannedUserPopup: false,
    showFCANotificationPopup: false,
    showApplePayNotificationPopup: false,
    showCryptocurrencyIsNotAvailablePopup: false,
    isPaymentProcessorNotFound: false,
    cryptoFlow: CRYPTO_FLOWS_MAP.BUY,
    apiVersion: 'v1',
    isOneClickFlow: false,
    successReturnURL: null,
    failureReturnURL: null,
  },

  getters: {
    isLanguageSwitching: state => state.isLanguageSwitching,
    isTranslationsLoaded: state => state.isTranslationsLoaded,
    isRequestLoaded: (store, getters, globalState, globalGetters) => !globalGetters['request/isLoading'],
    funnelError: (state, getters, globalState, globalGetters) => globalGetters['external/error'],
    // TODO: DIP violation. Should be refactored
    // @see https://gitlab.com/techcloud/shared/frontend-common-lib/blob/fe0f88212e6ce7fb39e30e35dbe7d73abb7e5e6a/src/payment-processor/checkout/Checkout.js#L24
    requestId: (store, getters) => getters['request/requestId'],
    cryptoWallets: (store, getters) => getters['request/wallet'],
    widgetReady: (store, getters) => getters.isTranslationsLoaded && getters.isRequestLoaded,
    themeConfig: ({ themeConfig }) => themeConfig,
    clientId: state => state.clientId,
    isThemeLoading: store => store.isThemeLoading,
    isInitCompleted: store => store.isInitCompleted,
    // TODO: why we check TokenService via getter? Without function it's not working, @see PD-18069. Should be refactored
    jwtTokenExists: () => () => TokenService.hasToken(),
    locale: (store, getters) => {
      // need to filter part for locales like en-US
      const localeToFind = store.locale.split('-')[0];

      return getters.availableLanguages.find(lang => lang === localeToFind) || 'en';
    },
    translationResource: (store, getters) => store.translationResource.replace(LANG_PLACEHOLDER, `_${getters.locale}`),
    availableLanguages: () => import.meta.env.VITE_APP_I18N_SUPPORTED_LANGUAGES.split(' '),
    hasTranslationsLoadingError: store => (
      store.lastWidgetError && store.lastWidgetError.type === ErrorType.TRANSLATION_LOADING_ERROR
    ),
    productId: ({ productId }) => productId,
    isCryptoAcquiring: ({ productType }) => productType === PRODUCT_TYPES.CRYPTO_ACQUIRING,
    isRequestWithQuote: ({ isRequestWithQuote }) => isRequestWithQuote,
    client: ({ client }) => {
      const { firstName = '', lastName = '' } = client;
      if (firstName || lastName) {
        client.fullName = `${firstName} ${lastName}`.trim();
      }

      return client;
    },
    nSure: ({ nSure }) => nSure,
    sift: ({ sift }) => sift,
    isZerohashAccepted: ({ isZerohashAccepted }) => isZerohashAccepted,
    // eslint-disable-next-line max-len
    showTransactionCryptocurrencyIsNotAvailablePopup: state => state.showTransactionCryptocurrencyIsNotAvailablePopup,
    showTransactionValidationErrorPopup: state => state.showTransactionValidationErrorPopup,
    showCoolingOffUserPopup: state => state.showCoolingOffUserPopup,
    messageCoolingOffUserPopup: state => state.messageCoolingOffUserPopup,
    showCryptocurrencyIsNotAvailablePopup: state => state.showCryptocurrencyIsNotAvailablePopup,
    showBannedUserPopup: state => state.showBannedUserPopup,
    showFCANotificationPopup: state => state.showFCANotificationPopup,
    showApplePayNotificationPopup: state => state.showApplePayNotificationPopup,
    isPaymentProcessorNotFound: state => state.isPaymentProcessorNotFound,
    cryptoFlow: state => state.cryptoFlow,
    isSellCryptoFlow: state => state.cryptoFlow === CRYPTO_FLOWS_MAP.SELL,
    isBuyCryptoFlow: state => state.cryptoFlow === CRYPTO_FLOWS_MAP.BUY,
    isSwapCryptoFlow: state => state.cryptoFlow === CRYPTO_FLOWS_MAP.SWAP,
    apiVersion: state => state.apiVersion,
    isV1: state => state.apiVersion === 'v1',
    isV2: state => state.apiVersion === 'v2',
    sourceInfo: (state, getters) => ({
      app: 'widget',
      source: getters.productId,
      requestId: getters.requestId, // XXX: should be removed
    }),
    isOneClickFlow: state => state.isOneClickFlow,
    cryptoPaymentMethod: state => state.cryptoPaymentMethod,
    isFrictionless: state => !state.cryptoPaymentMethod || state.cryptoPaymentMethod === 'manual',
    successReturnURL: state => state.successReturnURL,
    failureReturnURL: state => state.failureReturnURL,
    isCoinmamaTheme: state => state.themeConfig.serviceName === 'Coinmama',
    isKinesisTheme: state => state.themeConfig.serviceName === 'Kinesis',
    isMargonetworkTheme: state => state.themeConfig.serviceName === 'Margo',
    isPaybisAcquireTheme: state => state.themeConfig.serviceName === 'Paybis Crypto Acquiring',
    isUkCustomer: (state, rootGetters) => {
      const countryCode = rootGetters['request/countryCode'];
      const verifiedCountryCode = state.client.country;
      if (verifiedCountryCode) {
        return FCA_COUNTRY_CODE_LIST.includes(verifiedCountryCode);
      }

      return FCA_COUNTRY_CODE_LIST.includes(countryCode);
    },
    isShowCoolingOffPopUp: ({ client }) => !!client.showInvitationPopup,
  },

  mutations: {
    [SET_LANGUAGE_SWITCHING](state, payload) {
      state.isLanguageSwitching = payload;
    },

    [SET_TRANSLATION_STATUS](state, status) {
      state.isTranslationsLoaded = status;
    },

    [SET_ERROR](state, error) {
      state.lastWidgetError = error;
    },

    [SET_WIDGET_THEME_CONFIG](state, themeConfig) {
      state.themeConfig = themeConfig;
    },

    [SET_IS_THEME_LOADING](state, value) {
      state.isThemeLoading = value;
    },

    [SET_IS_INIT_COMPLETED](state, value) {
      state.isInitCompleted = value;
    },

    [SET_LOCALE](state, value) {
      state.locale = value;
    },

    [SET_TRANSLATION_RESOURCE](state, value) {
      state.translationResource = value;
    },
    [SET_PRODUCT_ID](state, value) {
      state.productId = value;
    },
    [SET_PRODUCT_TYPE](state, value) {
      state.productType = value;
    },

    [SET_IS_REQUEST_WITH_QUOTE](state, value) {
      state.isRequestWithQuote = value;
    },

    [SET_CLIENT](state, value) {
      state.client = value;
    },

    [SET_CLIENT_ID](state, value) {
      state.clientId = value;
    },

    [SET_NSURE](state, value) {
      if (window && value.nSurePartnerId) {
        // HOTFIX: pass nsure partner id
        window.nSurePartnerId = value.nSurePartnerId;
      }

      state.nSure = value;
    },

    [SET_SIFT](state, value) {
      setSiftSessionId(value.sessionId);
      setSiftAccount(value.beaconKey);

      state.sift = value;
    },

    [SET_IS_SET_TOKEN](state, value) {
      state.isSetToken = value;
    },

    [SET_IS_PAYMENT_PROCESSOR_NOT_FOUND](state, value) {
      state.isPaymentProcessorNotFound = value;
    },

    [SHOW_TRANSACTION_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP](state) {
      state.showTransactionCryptocurrencyIsNotAvailablePopup = true;
    },

    [SHOW_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP](state) {
      state.showCryptocurrencyIsNotAvailablePopup = true;
    },

    [SHOW_TRANSACTION_VALIDATION_ERROR_POPUP](state) {
      state.showTransactionValidationErrorPopup = true;
    },

    [SHOW_COOLING_OFF_USER_POPUP](state, message = '') {
      state.showCoolingOffUserPopup = true;
      state.messageCoolingOffUserPopup = message;
    },

    [HIDE_COOLING_OFF_USER_POPUP](state) {
      state.showCoolingOffUserPopup = false;
      state.messageCoolingOffUserPopup = '';
    },

    [SHOW_BANNED_USER_POPUP](state) {
      state.showBannedUserPopup = true;
    },

    [TOGGLE_FCA_NOTIFICATION_POPUP](state, payload) {
      state.showFCANotificationPopup = payload;
    },

    [TOGGLE_APPLE_PAY_NOTIFICATION_POPUP](state, payload) {
      state.showApplePayNotificationPopup = payload;
    },

    [SET_CRYPTO_FLOW](state, payload) {
      state.cryptoFlow = payload;
    },

    [SET_API_VERSION](state, payload) {
      state.apiVersion = payload;
    },

    [SET_ONE_CLICK_FLOW](state, payload) {
      state.isOneClickFlow = payload;
    },

    [SET_SUCCESS_RETURN_URL](state, payload) {
      state.successReturnURL = payload;
    },

    [SET_FAILURE_RETURN_URL](state, payload) {
      state.failureReturnURL = payload;
    },
  },

  actions: {
    async init({ commit, dispatch, getters, state, rootGetters }, {
      requestId: requestUuid,
      oneTimeToken,
      themeId,
      businessAccountProductUuid,
      transactionFlow: requestTransactionFlow,
      locale: qsLocale,
      partnerUserId,
      successReturnURL,
      failureReturnURL,
    }) {
      let requestId = requestUuid;
      let settings = null;
      const returnUrls = { successReturnURL, failureReturnURL };

      if (businessAccountProductUuid && requestUuid === null) {
        settings = await dispatch('loadBusinessAccountProductSettings', {
          businessAccountProductUuid,
          transactionFlow: requestTransactionFlow,
          partnerUserId,
          successReturnURL,
          failureReturnURL,
        });
        requestId = settings.requestId;
        returnUrls.successReturnURL = settings.successReturnUrl;
        returnUrls.failureReturnURL = settings.failureReturnUrl;
      }

      commit(SET_SUCCESS_RETURN_URL, returnUrls.successReturnURL);
      commit(SET_FAILURE_RETURN_URL, returnUrls.failureReturnURL);

      requestIdValidator(requestId);

      commit(SET_IS_THEME_LOADING, true);

      dispatch('external/setSettingsAppType', 'widget');

      // needed for the case when the widget reopens after 3d secure
      const previousRequestId = getters.requestId;

      const {
        apiVersion,
        quoteId,
        invoiceId,
        theme,
        locale,
        translationResource,
        productId,
        productType,
        parameters: {
          nSure = {},
          sift = {},
        } = {},
        paymentMethod,
        payoutMethod,
        cryptoPaymentMethod,
        depositCallbackUrl,
        transactionFlow = CRYPTO_FLOWS_MAP.BUY,
        status,
      } = settings || await dispatch('request/setRequestWithLoader', {
        requestId,
      });

      commit(SET_CRYPTO_FLOW, (requestTransactionFlow || transactionFlow));

      if (productType) {
        commit(SET_PRODUCT_TYPE, productType);
      }

      if (getters.isSellCryptoFlow && cryptoPaymentMethod) {
        setFrictionlessMode(cryptoPaymentMethod);
        setIsPaymentInitiated(status);

        if (depositCallbackUrl) {
          setDepositCallbackUrl(depositCallbackUrl);
        }
      }

      // XXX: set api version used for defining flow
      setApiVersion(apiVersion);
      commit(SET_API_VERSION, apiVersion);

      dispatch('setTheme', themeId || theme);

      commit(SET_NSURE, rootGetters['request/nSure']);
      commit(SET_SIFT, {
        sessionId: requestId,
        ...rootGetters['request/sift'],
      });
      dispatch('setProductId', productId);

      let translationsResourceUrl = translationResource;
      if (!translationsResourceUrl.startsWith('http')) {
        translationsResourceUrl = import.meta.env.VITE_APP_TRANSLATIONS_DIR + translationResource.replace(/^\/+/g, '');
      }

      commit(SET_TRANSLATION_RESOURCE, translationsResourceUrl.replace(`_${locale}`, LANG_PLACEHOLDER));

      dispatch('setLocale', qsLocale || locale);

      if (Boolean(import.meta.env.VITE_APP_HOTJAR_ENABLED) && state.themeConfig.hotJarId) {
        loadHotJarScript(state.themeConfig.hotJarId);

        const watchStopHandle = rootStore.watch(
          (state, getters) => [state.client.email, getters.clientId, getters['request/invoiceId']],
          ([email, clientId, invoiceId]) => {
            if (!email || !clientId || !invoiceId) {
              return;
            }
            if (window.hj) {
              window.hj('identify', clientId, { email, invoice: invoiceId });
            }
            watchStopHandle();
          },
          { immediate: true },
        );
      }

      commit(SET_IS_THEME_LOADING, false);

      await dispatch('initNextFlow', { quoteId, invoiceId, requestId, paymentMethod: paymentMethod || payoutMethod });

      commit(SET_IS_INIT_COMPLETED, true);
    },

    async loadBusinessAccountProductSettings({ commit, getters }, {
      businessAccountProductUuid,
      transactionFlow,
      partnerUserId,
      successReturnURL,
      failureReturnURL,
    }) {
      try {
        const params = {
          transactionFlow,
          partnerUserId,
          successReturnURL,
          failureReturnURL,
        };

        const data = await WidgetServiceV2
          .getBusinessAccountProductSettings({ businessAccountProductUuid, params, locale: getters.locale });

        commit(`request/${REQUEST.SET_REQUEST}`, data);

        return data;
      } catch ({ message }) {
        throw new AppCriticalError(message);
      }
    },

    async initNextFlow({ commit, dispatch, getters }, { quoteId, invoiceId, requestId, paymentMethod }) {
      // If invoiceId exists, it means that we need land user on the transaction-funnel page. For this we need init transaction-funnel store
      if (invoiceId) {
        await dispatch('external/setFunnelInvoiceId', {
          invoiceId,
        }, {
          root: true,
        });

        return;
      }

      // initiate the quote
      if (quoteId) {
        try {
          commit(SET_IS_REQUEST_WITH_QUOTE, true);
          const quote = await dispatch('v2/widgetQuote/getQuote', { quoteId, paymentMethod }, {
            root: true,
          });

          const requestBody = {
            quoteId,
            requestId,
          };

          if (quote.paymentMethods && quote.paymentMethods[0]) {
            requestBody.paymentMethod = quote.paymentMethods[0].paymentMethod;
          } else if (quote.payoutMethods && quote.payoutMethods[0]) {
            requestBody.payoutMethod = quote.payoutMethods[0].paymentMethod;
          } else {
            throw new AppCriticalError('No payment or payout methods');
          }

          let promoCode = {};

          // XXX: only request promoCode details, if promo codes are allowed
          if (getters['request/isPromoCodesEnabled'] && !getters.isUkCustomer) {
            const response = await WidgetServiceV2.refreshPaymentOrPayoutMethods(requestBody);

            promoCode = response.promoCode;
          }

          if (promoCode?.code) {
            await dispatch('v2/widgetQuote/fetchQuote', {
              amount: quote.requestedAmount.amount,
              currencyCodeFrom: quote.currencyCodeFrom,
              currencyCodeTo: quote.currencyCodeTo,
              directionChange: quote.direction,
              isReceivedAmount: getters['exchangeForm/isReceivedAmount'],
              promoCode: promoCode.code,
            }, { root: true });

            dispatch('promoCode/setPromoCode', promoCode.code);

            const updatedPromoCodeError = getters['v2/widgetQuote/rawQuote'].promoCodeError;

            if (updatedPromoCodeError?.code) {
              dispatch('promoCode/setError', true);
              dispatch('promoCode/setCapture', updatedPromoCodeError.message);
            }
          }

          const isOneClickFlow = isOneClickFlowCanBeUsed(quote, paymentMethod);

          if (isOneClickFlow) {
            commit(SET_ONE_CLICK_FLOW, true);
          }

          dispatch('v2/widgetQuote/updatePaymentMethodByQuote', { paymentMethod }, { root: true });

          await dispatch('exchangeForm/init', requestId);

          return;
        } catch (e) {
          // XXX: rollback
          commit(SET_IS_REQUEST_WITH_QUOTE, false);

          if (e instanceof AppCriticalError) {
            throw e;
          }
        }
      }

      // Otherwise we need to land user on the exchange-form. For this we need init exchange-form store
      await dispatch('exchangeForm/init', requestId);
    },

    async createTransaction(
      { commit, getters, dispatch, rootGetters },
      { paymentMethod, payoutMethod } = {},
    ) {
      let invoiceId = getters['external/funnelInvoiceId'];
      if (!invoiceId) {
        const handleCreateTransactionError = error => {
          const { response = {} } = error;
          if (response.data) {
            const { code } = response.data;
            const { status } = response;

            switch (code) {
              case 'CRYPTOCURRENCY_NOT_AVAILABLE':
                commit(SHOW_TRANSACTION_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP);
                break;
              case 'USER_IS_BANNED':
                dispatch('handleBannedUser');
                break;
              case 'VALIDATION_ERROR':
                commit(SHOW_TRANSACTION_VALIDATION_ERROR_POPUP);
                break;
              case 'COOLING_OFF_PERIOD':
                commit(SHOW_COOLING_OFF_USER_POPUP, response.data.errors[0]?.message ?? '');
                throw new UserForbiddenError(code);
              case 'REQUEST_HAS_INVALID_PRODUCT_ID':
                dispatch('clearUserInfo');
                throw new UserForbiddenError();
              default:
            }

            // TODO: should be moved into eventBus layer when it will be refactored
            if (status && parseInt(status) === 403) {
              dispatch('clearUserInfo');
              throw new UserForbiddenError();
            }
          }

          throw new AppCriticalError('Could not create transaction');
        };

        if (rootGetters['widgetQuote/quoteId'] !== rootGetters['request/quoteId']) {
          await dispatch('exchangeForm/assignQuoteToRequest', { root: true });
        }

        if (isV2()) {
          // XXX: apm
          try {
            const requestBody = {
              requestId: getters.requestId,
              paymentMethod,
              payoutMethod,
            };
            const data = rootGetters['request/invoiceId']
              ? await WidgetServiceV2.updateTransaction(requestBody)
              : await WidgetServiceV2.createTransaction(requestBody);

            if (rootGetters['request/invoiceId']) {
              dispatch('payoutDetailsFields/init', []);
            }
            invoiceId = data.invoiceId;
          } catch (error) {
            handleCreateTransactionError(error);
          }
        } else {
          try {
            const data = await WidgetService.createTransaction(
              getters.requestId,
            );
            invoiceId = data.invoiceId;
          } catch (error) {
            handleCreateTransactionError(error);
          }
        }
      }

      // We need populate invoiceId to the external transaction-funnel store, so it will be able to process the transaction
      await dispatch('external/setFunnelInvoiceId', {
        invoiceId,
      }, {
        root: true,
      });

      return invoiceId;
    },

    async authenticate({ dispatch }, { tokens }) {
      await dispatch('setTokens', tokens);
    },

    async loadClientData({ commit, getters }) {
      if (!getters.client.email || getters.isShowCoolingOffPopUp) {
        const client = await WidgetService.getClientDetails();
        commit(SET_CLIENT, client);
      }
    },

    async setTheme({ commit, state }, themeId = null) {
      const themeName = getThemeName(themeId);

      loadPartnersCss(themeName);

      try {
        const config = await loadPartnerJSON(themeName);
        commit(SET_WIDGET_THEME_CONFIG, {
          ...config,
          uuid: themeId,
          id: themeName,
        });
      } catch (error) {
        throw new AppCriticalError(error.message);
      } finally {
        commit(SET_IS_THEME_LOADING, false);
      }

      if (state.themeConfig.isDarkTheme) {
        document.body.classList.add('theme-dark');
      }
    },

    setLocale({ commit, dispatch }, value) {
      commit(SET_LOCALE, value);
      dispatch('external/setLanguage', value);
    },

    setProductId({ commit }, value) {
      commit(SET_PRODUCT_ID, value);
    },

    endLoadTranslation({ commit }) {
      commit(SET_TRANSLATION_STATUS, true);
    },

    startLoadTranslation({ commit }) {
      commit(SET_TRANSLATION_STATUS, false);
    },

    setTokens({ commit }, tokens) {
      const { sub: userId, email } = parseJwt(tokens.jwt);
      setSiftUserId(email);
      commit(SET_CLIENT_ID, userId);

      TokenService.setTokens(tokens);

      // TODO: Add reactivity to getter jwtTokenExists
      // TODO: should be removed or refactored
      commit(SET_IS_SET_TOKEN, true);
    },

    clearUserInfo({ commit }) {
      TokenService.removeTokens();
      commit(SET_CLIENT_ID, null);
      commit(SET_CLIENT, {});
    },

    handleBannedUser({ commit, dispatch }) {
      commit(SHOW_BANNED_USER_POPUP);
      dispatch('clearUserInfo');
    },

    async logout({ commit, dispatch }) {
      dispatch('paymentErrorPopup/setShowPaymentErrorCallout', false);
      await authService.logout();

      dispatch('clearUserInfo');

      return Promise.resolve();
    },

    setError({ commit }, error) {
      commit(SET_ERROR, error);
    },

    async getFeatureFlags({ dispatch }) {
      const data = await WidgetService.getFeatureFlags();
      dispatch('external/setFeatureFlags', data);
    },

    async setZerohashAcceptance() {
      await ProcessingService.saveZerohashAcceptance();
    },

    setLanguageSwitching({ commit }, payload) {
      commit(SET_LANGUAGE_SWITCHING, payload);
    },

    showApplePayNotificationPopup({ commit }) {
      commit(TOGGLE_APPLE_PAY_NOTIFICATION_POPUP, true);
    },

    hideApplePayNotificationPopup({ commit }) {
      commit(TOGGLE_APPLE_PAY_NOTIFICATION_POPUP, false);
    },

    showFCANotificationPopup({ commit }) {
      commit(TOGGLE_FCA_NOTIFICATION_POPUP, true);
    },

    hideFCANotificationPopup({ commit }) {
      commit(TOGGLE_FCA_NOTIFICATION_POPUP, false);
    },

    hideCoolingOffUserPopup({ commit }) {
      commit(HIDE_COOLING_OFF_USER_POPUP);
    },

    showCryptoCurrencyIsNotAvailablePopup({ commit }) {
      commit(SHOW_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP);
    },

    setPaymentProcessorNotFound({ commit }, payload) {
      commit(SET_IS_PAYMENT_PROCESSOR_NOT_FOUND, payload);
    },
  },

  modules: {
    promoCode,
    request: requestStore,
    widgetQuote,
    transactionHistory,
    root,
    menu: menuStore,
    external: externalStore,
    exchangeForm,

    'v2/paymentMethods': PaymentMethodsV2,
    'v2/widgetQuote': WidgetQuoteV2,
    'v2/frictionless': frictionless,
  },
});

refreshQuoteWithPaymentMethodSubscriber(rootStore);
registerExtraModules(rootStore);

setTimeout(() => {
  rootStore.watch(
    (state, getters) => getters['external/funnelNavigationCurrent'],
    (route = {}, oldRoute = {}) => {
      if (['payment-waiting', 'payout-waiting'].includes(route.name) && route.name !== oldRoute.name) {
        rootStore.dispatch('loadClientData');
      }
    },
  );

  rootStore.watch(
    (state, getters) => [
      getters['external/isPaymentFailed'],
      getters['card/selectedCardId'],
    ],
    ([isPaymentFailed, selectedCardId]) => {
      if (!isPaymentFailed || selectedCardId !== 'new') {
        return;
      }

      rootStore.dispatch('paymentErrorPopup/disallowPaymentPopup', null, {
        root: true,
      });
    },
  );

  // XXX: Need to prevent appearing of the card add form after this error
  rootStore.watch(
    (state, getters) => [
      getters.isPaymentProcessorNotFound,
      getters['card/selectedCardId'],
    ],
    ([isPaymentProcessorNotFound, selectedCardId]) => {
      if (!isPaymentProcessorNotFound || selectedCardId !== 'new') {
        return;
      }

      rootStore.commit('card/setSelectedCardId', null, {
        root: true,
      });
    },
  );

  rootStore.watch(
    (state, getters) => getters.clientId,
    clientId => {
      if (!clientId) {
        return;
      }

      waitUserInfo(rootStore);
    },
    {
      immediate: true,
    },
  );
}, 0); // setTimeout is needed to make sure that the store is already initialized within the app

export default rootStore;
