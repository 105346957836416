import dayjs from 'dayjs';

const retryTimes = 3; // how much retry retryable sumsub errors

export default {
  namespaced: true,
  state: {
    errors: [],
  },
  getters: {
    shouldRetry: state => error => {
      const similarErrorsForTheLastMinute = state.errors.filter(pastError => {
        const timeDiffInMinutes = dayjs().diff(pastError.getTime(), 'minutes');

        return pastError.equals(error) && timeDiffInMinutes < 1;
      });

      return similarErrorsForTheLastMinute.length <= retryTimes;
    },
  },
  mutations: {
    addError(state, error) {
      // TODO: Refactor. This can cause side effects due to immutability of state.
      state.errors.push(error);
    },
  },
};
