// TODO: this module is redundant and store the information that can be stored in `WidgetMenuLayout` component. Remove it after refactoring
import { MENU } from './mutations';

import { oneTimeTokenLogin } from '@/v1/utils/oneTimeTokenLogin';

export default {
  namespaced: false,

  state: {
    isMenuOpened: false,
    currentMenuItem: null,
    lastMenuItem: null,
  },

  getters: {
    isMenuOpened: state => state.isMenuOpened,
    currentMenuItem: state => state.currentMenuItem,
    lastMenuItem: state => state.lastMenuItem,
  },

  mutations: {
    [MENU.MENU_TOGGLE]: state => {
      state.isMenuOpened = !state.isMenuOpened;

      if (!state.isMenuOpened) {
        state.currentMenuItem = null;
      }
    },

    [MENU.MENU_ITEM_OPEN]: (state, item) => {
      state.currentMenuItem = item;
    },

    [MENU.MENU_LAST_ITEM]: (state, item) => {
      state.lastMenuItem = item;
    },

    [MENU.SET_STATE]: (state, payload) => {
      state.isMenuOpened = payload;
    },
  },

  actions: {
    toggleMenu({ getters, commit, rootGetters }) {
      if (!getters.isMenuOpened && !rootGetters.clientId) {
        oneTimeTokenLogin();
      }

      if (getters.currentMenuItem) {
        commit(MENU.MENU_LAST_ITEM, getters.currentMenuItem);
      }

      commit(MENU.MENU_TOGGLE);
    },

    selectMenuItem({ getters, commit }, { item }) {
      if (getters.currentMenuItem) {
        commit(MENU.MENU_LAST_ITEM, getters.currentMenuItem);
      }

      commit(MENU.MENU_ITEM_OPEN, item);
    },
  },
};
