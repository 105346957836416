import { EXCHANGE_FORM } from './mutations';
// eslint-disable-next-line import/no-cycle
import currencyStore from './currency';
import { isV2 } from '@/services/apiVersion';
import { CRYPTO_FLOWS_MAP } from '@/constants/cryptoFlows';

const versionPrefix = isV2() ? 'v2/' : '';

const {
  SET_FROM_FIAT_AMOUNT,
  SET_FROM_AMOUNT,
  SET_FROM_CURRENCY,
  SET_TO_AMOUNT,
  SET_TO_CURRENCY_CODE,
  SET_DIRECTION,
  SET_IS_LOADING,
  SET_ERROR,
  SET_IS_RECEIVED_AMOUNT,
  RESET_ERRORS,
  SET_UNEXPECTED_QUOTE_ERROR,
} = EXCHANGE_FORM;

export default {
  namespaced: true,

  state: {
    // TODO: align with names `fromAmount` and `amountFrom`. Follow the single approach
    fromAmount: null,
    fromCurrency: null,
    toAmount: null,
    toCurrency: null,
    toCurrencyCode: null,
    direction: 'from',
    fromFiatAmount: 0,
    isLoading: false,
    error: null,
    unexpectedQuoteError: null,
    isReceivedAmount: false,
  },

  getters: {
    fromAmount: state => state.fromAmount,
    fromCurrency: state => state.fromCurrency,
    toAmount: state => state.toAmount,
    toCurrency: state => state.toCurrency,
    toCurrencyCode: (state, getters, rootState, rootGetters) => {
      const walletCurrency = rootGetters['request/walletCurrency'];
      if (!walletCurrency) return state.toCurrencyCode;
      const currencies = getters.allCurrenciesWithPair(getters.fromCurrency);
      const predefinedCurrency = currencies.find(({ to }) => to === walletCurrency);

      return predefinedCurrency || currencies[0];
    },
    isLoading: (state, getters, rootState, rootGetters) => state.isLoading || rootGetters[`${versionPrefix}widgetQuote/isQuoteLoading`],

    quote: (state, getters, rootState, rootGetters) => {
      const widgetQuote = rootGetters['widgetQuote/quote'];
      const quote = {
        ...widgetQuote,
        amountFrom: getters.fromAmount,
        amountTo: getters.toAmount,
        currencyCodeFrom: getters.fromCurrency,
        currencyCodeTo: getters.toCurrencyCode,
        currencySourceFrom: getters.fromDisplayName,
      };

      quote.currencyCodeToFixed = rootGetters['request/wallet'].length === 1;

      return quote;
    },
    error: state => state.error,
    unexpectedQuoteError: state => state.unexpectedQuoteError,
    fromFiatAmount: state => state.fromFiatAmount,
    isReceivedAmount: state => state.isReceivedAmount,
    direction: state => state.direction,
  },

  mutations: {
    [SET_FROM_FIAT_AMOUNT]: (state, value) => {
      state.fromFiatAmount = value;
    },
    [SET_FROM_AMOUNT]: (state, amount) => {
      state.fromAmount = amount;
    },
    [SET_FROM_CURRENCY]: (state, currency) => {
      if (!currency) {
        return;
      }
      state.fromCurrency = currency;
    },
    [SET_TO_AMOUNT]: (state, amount) => {
      state.toAmount = amount;
    },
    [SET_TO_CURRENCY_CODE]: (state, currency) => {
      if (!currency) {
        return;
      }
      state.toCurrencyCode = currency;
    },
    [SET_DIRECTION]: (state, direction) => {
      state.direction = direction;
    },
    [SET_IS_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    [SET_ERROR]: (state, message) => {
      state.error = message;
    },
    [SET_UNEXPECTED_QUOTE_ERROR]: (state, message) => {
      state.unexpectedQuoteError = message;
    },
    [SET_IS_RECEIVED_AMOUNT]: (state, value) => {
      state.isReceivedAmount = value;
    },
    [RESET_ERRORS]: state => {
      state.error = null;
      state.unexpectedQuoteError = null;
    },
  },

  actions: {
    async assignQuoteToRequest({ rootGetters, dispatch }) {
      const { quoteId } = rootGetters['widgetQuote/quote'];
      dispatch('request/assignQuoteId', quoteId, {
        root: true,
      });

      return Promise.resolve();
    },

    updateQuote({ commit }, {
      amountFrom,
      amountTo,
      amountFromAmount,
      amountToAmount,
      currencyCodeFrom,
      currencyCodeTo,
    }) {
      commit(SET_IS_LOADING, true);

      commit(SET_FROM_AMOUNT, amountFromAmount || amountFrom);
      commit(SET_FROM_CURRENCY, currencyCodeFrom);

      commit(SET_TO_AMOUNT, amountToAmount || amountTo);
      commit(SET_TO_CURRENCY_CODE, currencyCodeTo);

      commit(SET_IS_LOADING, false);
    },

    async requestNewQuote({ dispatch, commit, getters, rootGetters, state }) {
      const fromEmpty = state.direction === 'from' && !getters.fromAmount;
      const toEmpty = state.direction === 'to' && !getters.toAmount;

      if ((fromEmpty || toEmpty) && !state.fromFiatAmount) return;

      commit(SET_IS_LOADING, true);
      commit(RESET_ERRORS);

      try {
        let amount;
        if (state.fromFiatAmount) {
          amount = state.fromFiatAmount;
        } else {
          amount = state.direction === 'from' ? getters.fromAmount : getters.toAmount;
        }

        const quote = await dispatch('widgetQuote/generateQuote', {
          amount,
          currencyCodeFrom: getters.fromCurrency,
          currencyCodeTo: getters.toCurrencyCode, // XXX: asset id
          directionChange: state.direction,
          isReceivedAmount: !!state.fromFiatAmount,
          promoCode: rootGetters['promoCode/promoCode'],
        }, {
          root: true,
        });

        commit(SET_FROM_AMOUNT, quote.amountFrom);
        commit(SET_FROM_CURRENCY, quote.currencyCodeFrom);
        commit(SET_TO_AMOUNT, quote.amountTo);
        commit(SET_TO_CURRENCY_CODE, quote.currencyCodeTo);
      } catch (rawError) {
        if (rawError.response.status === 500) {
          dispatch('deleteQuote');
          commit(SET_UNEXPECTED_QUOTE_ERROR, 'widget.exchange-form.error.calculating-a-quote');

          return;
        }

        const response = rawError.response?.data;
        if (!response || response.code !== 'VALIDATION_ERROR') return;
        const [error] = response.errors;
        const { response: quote } = response;

        commit(SET_ERROR, error.message);
        // TODO: looks like a hack, but this is the best way to do it, while BE returns quote inside error
        dispatch('widgetQuote/setErrorQuote', quote, {
          root: true,
        });

        commit(SET_FROM_AMOUNT, quote.amountFrom);
        commit(SET_FROM_CURRENCY, quote.currencyCodeFrom);
        commit(SET_TO_AMOUNT, quote.amountTo);
        commit(SET_TO_CURRENCY_CODE, quote.currencyCodeTo);
      } finally {
        commit(SET_IS_LOADING, false);
      }
    },

    deleteQuote({ commit, dispatch }) {
      commit(SET_IS_LOADING, false);
      commit(SET_FROM_AMOUNT, '');
      commit(SET_TO_AMOUNT, '');
      commit(RESET_ERRORS);

      if (isV2()) {
        dispatch('v2/widgetQuote/deleteQuote', {}, {
          root: true,
        });
      }

      dispatch('widgetQuote/deleteQuote', {}, {
        root: true,
      });
    },

    async setPromoCode({ dispatch, commit, getters, rootGetters }) {
      dispatch('setLoading', true);
        if (isV2()) {
          const { amountFrom, amountTo, amountReceived } = rootGetters['widgetQuote/quote'];

          const {
            toCurrencyCode: currencyCodeTo,
            fromCurrency: currencyCodeFrom,
            isReceivedAmount,
            fromFiatAmount,
            direction,
          } = getters;

          if (!(amountFrom && amountTo)) {
            commit(SET_IS_LOADING, false);
            // XXX: early return
            return;
          }

          let amount;
          if (fromFiatAmount) {
            amount = amountReceived;
          } else {
            amount = direction === 'from' ? amountFrom : amountTo;
          }

          const fromPaymentMethod = rootGetters['v2/paymentMethods/fromPaymentMethod'];

          const quote = await dispatch('v2/widgetQuote/fetchQuote', {
            amount,
            currencyCodeFrom,
            currencyCodeTo,
            promoCode: rootGetters['promoCode/promoCode'],
            directionChange: direction,
            isReceivedAmount,
            ...fromPaymentMethod,
          }, { root: true });

          dispatch('updateQuote', quote);

          return;
        }

      const { fromAmount, toAmount } = getters;
      if (fromAmount && toAmount) {
        await dispatch('requestNewQuote');
      }
     },

    async setAmountFiat({ dispatch, commit, getters, rootGetters }, amount) {
      if (isV2()) {
        const { isSellCryptoFlow } = rootGetters;
        const direction = isSellCryptoFlow ? 'to' : 'from';
        commit(SET_DIRECTION, direction);
        commit(SET_FROM_FIAT_AMOUNT, amount);
        commit(SET_IS_RECEIVED_AMOUNT, !isSellCryptoFlow);

        const { fromCurrency: currencyCodeFrom, toCurrencyCode: currencyCodeTo } = getters;

        const paymentMethod = isSellCryptoFlow
          ? rootGetters['v2/paymentMethods/fromPaymentMethod']
          : rootGetters['v2/paymentMethods/toPaymentMethod'];

        const quote = await dispatch('v2/widgetQuote/fetchQuote', {
          amount,
          currencyCodeFrom,
          currencyCodeTo,
          promoCode: rootGetters['promoCode/promoCode'],
          directionChange: direction,
          isReceivedAmount: !isSellCryptoFlow,
          ...paymentMethod,
        }, { root: true });

        dispatch('updateQuote', quote);

        return;
      }

      commit(SET_DIRECTION, 'from');
      commit(SET_FROM_FIAT_AMOUNT, amount);
      commit(SET_IS_RECEIVED_AMOUNT, true);
      await dispatch('requestNewQuote');
    },

    async setAmountFrom({ dispatch, commit, getters, rootGetters }, amount) {
      if (isV2()) {
        commit(SET_DIRECTION, 'from');
        commit(SET_FROM_AMOUNT, amount);
        commit(SET_FROM_FIAT_AMOUNT, null);
        commit(SET_IS_RECEIVED_AMOUNT, false);

        const { fromCurrency: currencyCodeFrom, toCurrencyCode: currencyCodeTo } = getters;

        const fromPaymentMethod = rootGetters['v2/paymentMethods/fromPaymentMethod'];

        let quote = {
          amountFromAmount: '0',
          amountToAmount: '0',
          currencyCodeFrom,
          currencyCodeTo,
        };

        if (amount > 0) {
          quote = await dispatch('v2/widgetQuote/fetchQuote', {
            amount,
            currencyCodeFrom,
            currencyCodeTo,
            promoCode: rootGetters['promoCode/promoCode'],
            directionChange: 'from',
            isReceivedAmount: false,
            ...fromPaymentMethod,
          }, { root: true });
        }
        await dispatch('updateQuote', quote);

        return Promise.resolve();
      }

      commit(SET_DIRECTION, 'from');
      commit(SET_FROM_AMOUNT, amount);
      commit(SET_FROM_FIAT_AMOUNT, null);
      commit(SET_IS_RECEIVED_AMOUNT, false);
      await dispatch('requestNewQuote');

      return Promise.resolve();
    },

    async setCurrencyCodeFrom({ dispatch, commit, getters, rootGetters }, currencyCode) {
      if (isV2()) {
        commit(SET_FROM_CURRENCY, currencyCode);

        const { amountFrom, amountTo, amountReceived } = rootGetters['widgetQuote/quote'];
        const {
          toCurrencyCode: currencyCodeTo,
          isReceivedAmount,
          fromFiatAmount,
          direction,
        } = getters;
        if (!(amountFrom && amountTo)) {
          // XXX: early return
          return;
        }

        let amount;
        if (fromFiatAmount) {
          amount = amountReceived;
        } else {
          amount = direction === 'from' ? amountFrom : amountTo;
        }

        const fromPaymentMethod = rootGetters['v2/paymentMethods/fromPaymentMethod'];
        const notFoundMethodsCurrency = rootGetters['v2/paymentMethods/notFoundMethodsCurrency'];

        if (!notFoundMethodsCurrency) {
          const quote = await dispatch('v2/widgetQuote/fetchQuote', {
            amount,
            currencyCodeFrom: currencyCode,
            currencyCodeTo,
            promoCode: rootGetters['promoCode/promoCode'],
            directionChange: direction,
            isReceivedAmount,
            ...fromPaymentMethod,
          }, { root: true });

          dispatch('updateQuote', quote);
        }

        return;
      }

      commit(SET_FROM_CURRENCY, currencyCode);
      const { fromAmount, toAmount } = getters;
      if (fromAmount && toAmount) {
        await dispatch('requestNewQuote');
      }
    },

    async setAmountTo({ dispatch, commit, getters, rootGetters }, amount) {
      if (isV2()) {
        commit(SET_DIRECTION, 'to');
        commit(SET_TO_AMOUNT, amount);
        commit(SET_FROM_FIAT_AMOUNT, null);
        commit(SET_IS_RECEIVED_AMOUNT, true);

        const { fromCurrency: currencyCodeFrom, toCurrencyCode: currencyCodeTo } = getters;

        const toPaymentMethod = rootGetters['v2/paymentMethods/toPaymentMethod'];

        let quote = {
          amountFromAmount: '0',
          amountToAmount: '0',
          currencyCodeFrom,
          currencyCodeTo,
        };

        if (amount > 0) {
          quote = await dispatch('v2/widgetQuote/fetchQuote', {
            amount,
            currencyCodeFrom,
            currencyCodeTo,
            promoCode: rootGetters['promoCode/promoCode'],
            directionChange: 'to',
            isReceivedAmount: true,
            ...toPaymentMethod,
          }, { root: true });
        }
        await dispatch('updateQuote', quote);

        return Promise.resolve();
      }

      commit(SET_DIRECTION, 'to');
      commit(SET_TO_AMOUNT, amount);
      commit(SET_FROM_FIAT_AMOUNT, null);
      commit(SET_IS_RECEIVED_AMOUNT, true);
      await dispatch('requestNewQuote');

      return Promise.resolve();
    },

    async setCurrencyCodeTo({ dispatch, commit, getters, rootGetters }, currencyCode) {
      if (isV2()) {
        commit(SET_TO_CURRENCY_CODE, currencyCode);

        const { amountFrom, amountTo, amountReceived } = rootGetters['widgetQuote/quote'];
        const {
          fromCurrency: currencyCodeFrom,
          fromFiatAmount,
          direction,
          isReceivedAmount,
        } = getters;
        if (!(amountFrom && amountTo)) {
          // XXX: early return
          return;
        }

        let amount;
        if (fromFiatAmount) {
          amount = amountReceived;
        } else {
          amount = direction === 'from' ? amountFrom : amountTo;
        }

        const toPaymentMethod = rootGetters['v2/paymentMethods/toPaymentMethod'];
        const notFoundMethodsCurrency = rootGetters['v2/paymentMethods/notFoundMethodsCurrency'];

        if (!notFoundMethodsCurrency) {
          const quote = await dispatch('v2/widgetQuote/fetchQuote', {
            amount,
            currencyCodeFrom,
            currencyCodeTo: currencyCode,
            promoCode: rootGetters['promoCode/promoCode'],
            directionChange: direction,
            isReceivedAmount,
            ...toPaymentMethod,
          }, { root: true });

          dispatch('updateQuote', quote);
        }

        return;
      }

      commit(SET_TO_CURRENCY_CODE, currencyCode);
      const { fromAmount, toAmount } = getters;
      if (fromAmount && toAmount) {
        await dispatch('requestNewQuote');
      }
    },

    async setLoading({ commit }, value) {
      await commit(SET_IS_LOADING, value);
    },

    async setStandaloneParams({ commit, getters, rootGetters, dispatch }, standaloneParams) {
      const defaultCurrency = rootGetters['request/defaultCurrency'];
      const {
        currencyCodeTo,
        currencyCodeFrom,
        amountFrom,
        amountTo,
        transactionFlow = CRYPTO_FLOWS_MAP.BUY,
      } = standaloneParams;

      const assetId = transactionFlow === CRYPTO_FLOWS_MAP.SELL ? (currencyCodeTo || defaultCurrency) : currencyCodeTo;
      const currencyCode = transactionFlow === CRYPTO_FLOWS_MAP.BUY ? (currencyCodeFrom || defaultCurrency) : currencyCodeFrom;

      if (transactionFlow === CRYPTO_FLOWS_MAP.BUY && !assetId) {
        return Promise.resolve();
      }

      if (transactionFlow === CRYPTO_FLOWS_MAP.SELL && !currencyCode) {
        return Promise.resolve();
      }

      const reshakePaymentMethodsFor = async ({
        currencyCode: scopedCurrencyCode,
        assetId: scopedAssetId,
      }) => {
        // Try to find currency code, if not found, reshake APMs
        let hasCurrencyCode = scopedCurrencyCode && getters.allCurrencies
          .find(({ from }) => from === scopedCurrencyCode);
        let hasAssetId = scopedAssetId && getters.allCurrencies
          .find(({ to }) => to === scopedAssetId);

        if (scopedCurrencyCode && !hasCurrencyCode && scopedAssetId && !hasAssetId) {
          const apmWithCurrencyCodes = getters.paymentMethodByCurrencies(...[
            scopedCurrencyCode,
            scopedAssetId,
          ]);

          if (apmWithCurrencyCodes) {
            // Update currency pairs and check again
            await dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', {
              paymentMethod: apmWithCurrencyCode.name,
            }, { root: true });

            hasCurrencyCode = getters.allCurrencies
              .find(({ from }) => from === scopedCurrencyCode);
            hasAssetId = getters.allCurrencies
              .find(({ to }) => to === scopedAssetId);
          }

          return {
            hasCurrencyCode,
            hasAssetId,
          };
        }

        // Try to find across all APMs
        if (scopedCurrencyCode && !hasCurrencyCode) {
          const apmWithCurrencyCode = getters.paymentMethodByCurrencies(...[
            scopedCurrencyCode,
            scopedAssetId,
          ]);

          if (apmWithCurrencyCode) {
            // Update currency pairs and check again
            await dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', {
              paymentMethod: apmWithCurrencyCode.name,
            }, { root: true });

            hasCurrencyCode = getters.allCurrencies
              .find(({ from }) => from === scopedCurrencyCode);
          }
        }

        // Try to find across all APMs
        if (scopedAssetId && !hasAssetId) {
          const apmWithAssetId = getters.paymentMethodByCurrencies(...[
            scopedCurrencyCode,
            scopedAssetId,
          ]);

          if (apmWithAssetId) {
            // Update currency pairs and check again
            await dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', {
              paymentMethod: apmWithAssetId.name,
            }, { root: true });

            hasAssetId = getters.allCurrencies
              .find(({ to }) => to === scopedAssetId);
          }
        }

        return {
          hasCurrencyCode,
          hasAssetId,
        };
      };

      // Sell Flow with optional parameter OR Buy Flow with optional parameter
      if (currencyCode && assetId) {
        const { hasCurrencyCode, hasAssetId } = await reshakePaymentMethodsFor({ currencyCode, assetId });

        if (hasCurrencyCode && hasAssetId) {
          commit(SET_FROM_CURRENCY, currencyCode);
          commit(SET_TO_CURRENCY_CODE, assetId);
        }
      } else {
        // Sell Flow OR Buy Flow with optional parameter
        if (currencyCode) {
          const { hasCurrencyCode } = await reshakePaymentMethodsFor({ currencyCode });

          if (hasCurrencyCode) {
            commit(SET_FROM_CURRENCY, currencyCode);
          }
        }

        // Buy Flow OR Sell Flow with optional parameter
        if (assetId) {
          const { hasAssetId } = await reshakePaymentMethodsFor({ assetId });

          if (hasAssetId) {
            commit(SET_TO_CURRENCY_CODE, assetId);
          }
        }
      }

      if (amountFrom) {
        await dispatch('setAmountFrom', amountFrom);

        return Promise.resolve();
      }

      if (amountTo) {
        await dispatch('setAmountTo', amountTo);

        return Promise.resolve();
      }

      return Promise.resolve();
    },
  },

  modules: {
    currency: currencyStore,
  },
};
